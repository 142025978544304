body {
    font-family: Vazir;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}
.container {
    max-width: 800px;
    position: relative;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 5px rgba(219, 47, 47, 0.1);
}
input[type="text"], textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}
input[type="submit"] {
    padding: 10px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    body {
        font-family: Vazir;
        margin: 0;
        padding: 0;
        background-color: #f0f0f0;
    }
    .container {
        max-width: 800px;
        margin: 50px auto;
        background-color: #f01919;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    }
    input[type="text"], textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing: border-box;
    }
    input[type="submit"] {
        padding: 10px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
    }

}
