.header {
  background-color: rgb(57, 95, 130);
  color: antiquewhite;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
}
.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav {
  display: flex;
}

.nav__list {
  display: flex;
  list-style-type: none;
}

.nav__item {
  margin-left: 20px;
  transition:  all 0.3s ease;
}
.nav__item:hover{
  color: rgb(57, 109, 130);
  background-color: rgb(255, 235, 205);
  border-radius: 1rem;
}

.nav__link {
  color: antiquewhite;
  text-decoration: none;
  padding: 10px;
  transition:  all 0.3s ease;
}

.nav__link:hover {
  color: rgb(57, 109, 130);
  background-color: blanchedalmond;
  border-radius: 1rem;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.hamburger__line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .nav {
    width: 100%;
  
    align-items: flex-start;
    display: none;
  }

  .nav--open {
    display: flex;
    
  }
  

  .nav__item {
    margin-left: 0;
    margin-bottom: 10px;
    


  }

  .hamburger {
    display: block;
  }
}
@media (max-width: 380px) {
  .nav__list{
    font-size: small;
  }
  .logo{
    font-size: small;
  }
}