@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Regular.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Regular.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Regular.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Regular.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Bold.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Bold.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Bold.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Bold.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Black.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Black.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Black.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Black.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Medium.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Medium.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Medium.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Medium.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Light.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Light.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Light.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Light.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url('../node_modules/vazir-font/dist/Vazir-Thin.eot');
  src: url('../node_modules/vazir-font/dist/Vazir-Thin.eot?#iefix') format('embedded-opentype'),
       url('../node_modules/vazir-font/dist/Vazir-Thin.woff2') format('woff2'),
       url('../node_modules/vazir-font/dist/Vazir-Thin.woff') format('woff'),
       url('../node_modules/vazir-font/dist/Vazir-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  font-size: larger;
  font-family: Vazir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Vazir;
}
