* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Vazir;
    background-color: #f4f4f4;
    color: #333;
}

.header2{
    background-color: rgb(57, 95, 130);
    color: antiquewhite;
    text-align: right;
    padding: 1rem 0;
    display: inline-block;
    border-radius: 20%;
    
}

main {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.product {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin: 1rem;
    width: 250px;
    text-align: center;
}

.product img {
    width: 100%;
    border-radius: 5px;
}

button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    cursor: pointer;
}

footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem 0;
}
.shopbtn{
    background-color: rgb(57, 95, 130);
    color: antiquewhite;
    border-radius: 10px;
}
/* @media (max-width: 768px){
    
} */